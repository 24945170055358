#page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 2.5rem; /* Footer height */
}

#footerMain {
    background-color: black;
    width: 100%;
    position: absolute;
}

.BespokeFooterClass {
    background: #34ce57;
    width: 100%;
    height: 2.5rem; /* Footer height */
}

.WhiteFont {
    color: white !important;
}

.WhiteBorder {
    border-color: white !important;
}

.ThinFont {
    font-weight: 100;
}